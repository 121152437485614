function validateCompare(text, textCompare, message) {
  if (text === textCompare) {
    return null;
  } else {
    return message;
  }
}

function validateRequire(text) {
  if (text) {
    return null;
  } else {
    return `ต้องกรอกช่องนี้`;
  }
}

function validateRegex(text, regex, discription) {
  if (!regex.test(text)) {
    return `กรุณากรอกตัวอักษรในรูปแบบ ${discription}`;
  } else {
    return null;
  }
}
function validatePassword(pass = "") {
  let condition = {
    minlength: {
      isvalid: false,
      text: "ความยาวอย่างน้อย 8 หลัก",
      minLength: 8,
      regex: /.{8}/g,
    },
    containLowercase: {
      isvalid: false,
      text: "อักษรภาษาอังกฤษ ตัวพิมพ์เล็กอย่างน้อย 1 ตัว",
      minLength: 1,
      regex: /[a-z]/g,
    },
    containUppercase: {
      isvalid: false,
      text: "อักษรภาษาอังกฤษ ตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว",
      minLength: 1,
      regex: /[A-Z]/g,
    },
    containNumbers: {
      isvalid: false,
      text: "ตัวเลขอย่างน้อย 1 ตัว",
      minLength: 1,
      regex: /[0-9]/g,
    },
    containDisallowSequentialChars: {
      isvalid: false,
      invert: true,
      text: "ตัวอักษรซ้ำกันไม่เกิน 2 ตัว",
      minLength: 2,
      regex: /(.)\1{2,}/g,
    },
    containSpecialChars: {
      isvalid: false,
      text: "อักขระพิเศษอย่างน้อย 1 ตัว",
      minLength: 1,
      regex: /[!@#$%^&*()_+{}\[\]:;<>,.?/~\\-]/g,
    },
    // containUsername: {
    //   isvalid: false,
    //   invert: true,
    //   text: "ห้ามมี username อยู่ในรหัสผ่าน",
    //   minLength: 1,
    //   regex: new RegExp(username, "i"),
    // },
    countainConsecutiveChars3: {
      isvalid: false,
      invert: true,
      text: "ห้ามมีตัวเลขหรืออักษรเรียงกันเกิน 3 ตัว",
      minLength: 3,
      regex:
        /(?:012|123|234|345|456|567|678|789|ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz)/,
    },
  };

  Object.keys(condition).map((x) => {
    let isvalid = condition[x].regex.test(pass);
    if (condition[x]?.invert) isvalid = !isvalid;
    condition[x].isvalid = isvalid;
  });
  return condition;
}

function validateEmail(text) {
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(text)) {
    return `ต้องเป็นประเภทอีเมล`;
  } else {
    return null;
  }
}
function validateMinCharacter(text, min) {
  if (text.length < min) {
    return `ต้องมีอย่างน้อย ${min} ตัวอักษรขึ้นไป`;
  } else {
    return null;
  }
}

module.exports = {
  validateRequire,
  validateRegex,
  validatePassword,
  validateEmail,
  validateMinCharacter,
  validateCompare,
};
